import React, { useContext } from "react";

import {
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Switch,
} from "@material-ui/core";

import BeeContext from "../BeeContext";
import { addPinForUid, removePinForUid } from "../firestore";

export default ({ anchorPosition, onClose, teamMember }) => {
  const bee = useContext(BeeContext);

  const isPin = bee?.pins?.includes(teamMember.email);

  const togglePin = () => {
    isPin
      ? removePinForUid(teamMember.email, bee.ref.id)
      : addPinForUid(teamMember.email, bee.ref.id);
  };

  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={onClose}
      open={Boolean(anchorPosition)}
    >
      <MenuItem>
        <ListItemText
          primary={teamMember.email}
          secondary="copy to clipboard"
          onClick={() => {
            navigator.clipboard.writeText(teamMember.email);
          }}
        />
      </MenuItem>

      {teamMember.phoneNumber && (
        <MenuItem>
          <ListItemText
            primary={teamMember.phoneNumber}
            secondary="copy to clipboard"
            onClick={() => {
              navigator.clipboard.writeText(teamMember.phoneNumber);
            }}
          />
        </MenuItem>
      )}

      <Divider />

      <MenuItem disabled={bee.ref.id === teamMember.ref.id} onClick={togglePin}>
        <ListItemText
          primary={`📌 Pin ${bee.ref.id === teamMember.ref.id ? "yourself" : "colleague"
            }`}
        />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={togglePin}
            checked={isPin}
            disabled={bee.ref.id === teamMember.ref.id}
          />
        </ListItemSecondaryAction>
      </MenuItem>
    </Menu>
  );
};
