import React, { useState } from "react";

import {
    Card,
    CardContent,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core";

import {
    LineWeight,
    PersonPinCircleOutlined,
    ViewComfy,
    ExitToApp,
    Info
} from "@material-ui/icons";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import {
    setStatusForUid,
    setSelectedLayoutForUid,
    setShowPinsForUid,
    setTeamForUid
} from "../firestore";

import { logout } from "../firebase";

import LoadingTopBar from "./LoadingTopBar";
import UserAvatar from "./UserAvatar";

import { LAYOUTS, STATUSES, NO_TEAM, IMG_TOOLTIP_INFO } from "../constants";

export default ({ bee, teams, selectedTeam, setSelectedTeam }) => {
    if (!bee) return <LoadingTopBar />;
    const beeTeam = teams.find((e) => e.code === bee.itTeamId);

    const [open, setOpen] = useState(false);

    const onLayoutChange = (_, newLayoutId) => {
        if (newLayoutId !== null) {
            setSelectedLayoutForUid(newLayoutId, bee.ref.id);
        }
    };

    const onTogglePins = (_, value) => setShowPinsForUid(value, bee.ref.id);

    const onStatusChange = (event) =>
        setStatusForUid(event.target.value, bee.ref.id);

    const onTeamChange = (event) => {
        setSelectedTeam(event.target.value);
        setTeamForUid(event.target.value, bee.ref.id);
    }

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        spacing={2}
                        item
                        alignItems="center"
                        xs={12}
                        sm={6}
                        md={7}
                    >
                        <Grid item>
                            <UserAvatar
                                avatar={bee.avatar}
                                name={bee.name}
                                status={bee.status}
                                grayscale={false}
                            />
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary" noWrap>
                                        {`${bee.name || "Unknown Team"}${beeTeam && beeTeam.code !== NO_TEAM ? ` - ${beeTeam.team}` : ""}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} title={IMG_TOOLTIP_INFO} placement="bottom">
                                        <IconButton size="small" onClick={() => setOpen(!open)}>
                                            <Info style={{ fontSize: 20 }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Select onChange={onStatusChange} value={bee.status}>
                                {Object.entries(STATUSES)
                                    .filter(
                                        ([id, { restricted }]) =>
                                            id === bee.status || restricted !== true
                                    )
                                    .map(([id, { string }]) => (
                                        <MenuItem key={id} value={id}>
                                            {string}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="flex-end"
                        xs={12}
                        sm={12}
                        md={5}
                        spacing={1}
                    >
                        <Grid item xs={"auto"} sm={"auto"} md={"auto"}>
                            <ToggleButton
                                onChange={onTogglePins}
                                selected={bee?.clientConfig?.web?.showPins}
                                size="small"
                                value={!bee?.clientConfig?.web?.showPins}
                            >
                                <PersonPinCircleOutlined />
                            </ToggleButton>
                        </Grid>

                        <Grid item xs={"auto"} sm={"auto"} md={"auto"}>
                            <ToggleButtonGroup
                                exclusive
                                onChange={onLayoutChange}
                                size="small"
                                value={bee.clientConfig?.web?.selectedLayout || LAYOUTS.ATOZ}
                            >
                                <ToggleButton value={LAYOUTS.ATOZ}>
                                    <ViewComfy />
                                </ToggleButton>
                                <ToggleButton value={LAYOUTS.STATUS}>
                                    <LineWeight />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        <Grid item xs={10} sm={5} md={4}>
                            <Select
                                fullWidth
                                onChange={onTeamChange}
                                value={selectedTeam}
                                defaultValue={NO_TEAM}
                            >
                                {teams
                                    .sort((a, b) => (a.team > b.team) ? 1 : -1)
                                    .map((team) => (
                                        <MenuItem key={team.code} value={team.code}>
                                            {team.team}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>

                        <Grid item xs={2} sm={1} md={1}>
                            <Tooltip title="Log Out">
                                <IconButton onClick={() => logout()}>
                                    <ExitToApp />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
