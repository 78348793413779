import React from "react";

import TeamLayoutAtoZ from "./TeamLayoutAtoZ";
import TeamLayoutStatus from "./TeamLayoutStatus";

import { LAYOUTS } from "../constants";

export default ({ layout, teamMembers }) => {
  switch (layout) {
    case LAYOUTS.STATUS:
      return <TeamLayoutStatus teamMembers={teamMembers} />;
    default:
      return <TeamLayoutAtoZ teamMembers={teamMembers} />;
  }
};
