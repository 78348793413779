import React, { useState } from "react";

import {
  Card,
  CardContent,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";

import UserAvatar from "./UserAvatar";
import UserContextMenu from "./UserContextMenu";

import { STATUSES } from "../constants";

export default ({ name, teamMembers = [] }) => {
  const [mousePos, setMousePos] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();

  const onTeamMemberClick = (event, teamMember) => {
    setMousePos({ top: event.clientY, left: event.clientX });
    setSelectedTeamMember(teamMember);
  };

  const handleMenuClose = () => {
    setMousePos(null);
    setSelectedTeamMember(null);
  };

  return (
    <Card>
      <CardContent>
        {name && <Typography variant="h6">{name}</Typography>}
        <Grid container direction="row" justifyContent="flex-start">
          {teamMembers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((teamMember) => (
              <Grid
                key={teamMember.ref.id}
                container
                item
                alignItems="center"
                lg={2}
                md={3}
                sm={4}
                xs={6}
              >
                <ListItem
                  button
                  dense
                  onClick={(event) => onTeamMemberClick(event, teamMember)}
                >
                  <ListItemAvatar>
                    <UserAvatar
                      avatar={teamMember.avatar}
                      name={teamMember.name}
                      status={teamMember.status}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={teamMember.name}
                    secondary={STATUSES[teamMember.status]?.string}
                  />
                </ListItem>
              </Grid>
            ))}

          {teamMembers.length === 0 && <Typography>None</Typography>}

          {selectedTeamMember && (
            <UserContextMenu
              anchorPosition={mousePos}
              onClose={handleMenuClose}
              teamMember={selectedTeamMember}
            />
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
