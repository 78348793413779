import React, { useEffect, useState } from "react";
import semver from "semver";
import { Grid } from "@material-ui/core";
import loadable from "@loadable/component";

import { onAuthStateChangedEffect, signInWithGooglePopup } from "./firebase";

import {
  getTeams,
  verifyUser,
  setTeamForUid,
  subscribeToBee,
  subscribeToConfig,
  subscribeToPins,
  subscribeToBees
} from "./firestore";

import BeeContext from "./BeeContext";

import SkeletonCard from "./components/SkeletonCard";
import TeamLayout from "./components/TeamLayout";
import TopBar from "./components/TopBar";
import TeamListingCard from "./components/TeamListingCard";

import { NO_TEAM, IT_GCP } from "./constants";
import { version } from "../package.json";

const AuthPageLoader = loadable(() =>
  import("./components/AuthPageLoader")
);


export default () => {
  const [user, setUser] = useState();
  const [bee, setBee] = useState();
  const [itTeams, setItTeams] = useState();
  const [teamMembers, setTeamMembers] = useState();
  const [filteredTeamMembers, setFilteredTeamMembers] = useState(undefined);
  const [selectedTeam, setSelectedTeam] = useState(NO_TEAM);
  const [pins, setPins] = useState();
  const [loading, setLoading] = useState(false);
  const [authPending, setAuthPending] = useState(true);
  const [authError, setAuthError] = useState("");

  const signInApp = async () => {
    setLoading(true);
    setAuthError("");

    signInWithGooglePopup()
      .catch((e) => {
        console.log(e);
        setAuthError("System could not authorize user.");
      })
      .finally(() => setLoading(false));
  };

  useEffect(onAuthStateChangedEffect(setAuthPending, setUser), [user]);

  useEffect(() => {
    return subscribeToConfig((snapshot) => {
      if (
        semver.lt(
          version,
          semver.coerce(snapshot.data()?.minimumVersion) || version
        )
      ) {
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      return subscribeToBee(user.uid, (snapshot) => {
        if (!snapshot.exists) {
          return verifyUser(user);
        } else {
          setBee({ ref: snapshot.ref, ...snapshot.data() });
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (!bee?.pins || bee.pins.length === 0) setPins();
    const promises = [];
    const maxInSize = 10;

    if (bee?.pins && bee.pins.length) {
      for (let x = 0; x < bee.pins.length; x = x + maxInSize) {
        promises.push(new Promise((resolve) => {
          subscribeToPins(bee.pins.slice(x, x + maxInSize), (snapshot) => {
            resolve(snapshot.docs.reduce(
              (team, doc) => [...team, { ref: doc.ref, ...doc.data() }],
              []
            ));
          });
        }));
      }

      Promise.all(promises).then((values) => {
        setPins([].concat.apply([], values));
      });
    }
  }, [bee]);

  useEffect(() => {
    if (bee) {
      const beeTeam = bee.clientConfig?.web?.selectedTeam

      // migrating from old bees to new bees, selectedTeam may be old value - set to no team if so
      if (beeTeam && beeTeam !== IT_GCP) {
        setSelectedTeam(beeTeam);
      } else {
        setSelectedTeam(NO_TEAM);
        setTeamForUid(NO_TEAM, bee.ref.id);
      }
    }
  }, [bee]);

  useEffect(() => {
    if (bee) {
      subscribeToBees(
        (snapshot) => {
          if (snapshot.empty) setTeamMembers([]);

          if (snapshot.docChanges().some(({ doc }) => doc.id !== bee.ref.id)) {
            setTeamMembers(
              snapshot.docs.reduce(
                (team, doc) => [...team, { ref: doc.ref, ...doc.data() }],
                []
              )
            );
          }
        }
      );
    }
  }, [bee]);

  useEffect(() => {
    if (teamMembers) {
      const filteredTeams = {};
      const allTeams = [];

      getTeams().then(snapshot => {
        snapshot.docs.forEach(doc => {
          filteredTeams[doc.data().code] = [];
          allTeams.push(doc.data());
        })

        for (const member of teamMembers) {
          if (member.itTeamId !== NO_TEAM) {
            filteredTeams[member.itTeamId].push(member);
          }

          filteredTeams[NO_TEAM].push(member);
        }

        setFilteredTeamMembers(filteredTeams);
        setItTeams(allTeams);
      })
    }
  }, [teamMembers]);

  const loadingString = !user
    ? "Authenticating…"
    : !bee
      ? "Loading…"
      : !teamMembers
        ? "Fetching..."
        : null;

  return (
    <div>
      {authPending ? (
        <AuthPageLoader
          authError={authError}
          isLoading={loading}
          requestAuth={signInApp}
        />
      ) : (
          <Grid container spacing={2}>
            {itTeams &&
              <Grid item xs={12}>
                <TopBar bee={bee} teams={itTeams} selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam} />
              </Grid>
            }

            {loadingString && (
              <Grid item xs={12}>
                <SkeletonCard prompt={loadingString} />
              </Grid>
            )}

            <BeeContext.Provider value={bee}>
              {bee?.clientConfig?.web?.showPins && (
                <Grid item xs={12}>
                  <TeamListingCard name={"Pinned"} teamMembers={pins} />
                </Grid>
              )}

              {filteredTeamMembers &&
                <TeamLayout
                  layout={bee?.clientConfig?.web?.selectedLayout}
                  teamMembers={selectedTeam === NO_TEAM ? teamMembers : filteredTeamMembers[selectedTeam]}
                />
              }
            </BeeContext.Provider>
          </Grid>
        )}
    </div>
  );
};
