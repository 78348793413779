import React from "react";

import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { STATUSES, STATUS_TYPES } from "../constants";

const initials = (name = "") => {
  const parts = name.split(" ");
  return parts[0][0] + (parts[1] || " ")[0];
};

const useStyles = makeStyles({
  root: {
    borderWidth: 4,
    borderStyle: "solid",
    borderColor: ({ status }) => STATUSES[status]?.color,
  },
  img: ({ grayscale, status }) => ({
    filter:
      grayscale && STATUSES[status]?.type !== STATUS_TYPES.AVAILABLE
        ? "grayscale(1)"
        : null,
  }),
});

export default ({ avatar, name, status, grayscale = true }) => (
  <Avatar src={avatar} classes={useStyles({ status, grayscale })}>
    {initials(name)}
  </Avatar>
);
