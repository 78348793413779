export const LAYOUTS = {
  ATOZ: "ATOZ",
  STATUS: "STATUS",
};

export const NO_TEAM = "NO_TEAM";

export const IT_GCP = "IT_GCP";

export const IMG_TOOLTIP_INFO = "To change your profile, please contact a member of Team PP.";

export const STATUS_TYPES = {
  AVAILABLE: "available",
  BUSY: "busy",
  UNAVAILABLE: "unavailable",
};

export const STATUSES = {
  AVAILABLE: {
    color: "#0076BA",
    emoji: "🏢",
    id: "AVAILABLE",
    string: "Available (office)",
    type: STATUS_TYPES.AVAILABLE,
  },
  AVAILABLE_REMOTE: {
    color: "#1FB100",
    emoji: "🏠",
    id: "AVAILABLE_REMOTE",
    string: "Available (remote)",
    type: STATUS_TYPES.AVAILABLE,
  },
  MEETING: {
    color: "#98EAD7",
    emoji: "📆",
    id: "MEETING",
    string: "Busy",
    type: STATUS_TYPES.BUSY,
  },
  LUNCH: {
    color: "#F2D4F4",
    emoji: "🍽️",
    id: "LUNCH",
    string: "On Lunch",
    type: STATUS_TYPES.BUSY,
  },
  FINISHED: {
    color: "#D5D5D5",
    emoji: "🏁",
    id: "FINISHED",
    string: "Finished for the day",
    type: STATUS_TYPES.UNAVAILABLE,
  },
  LEAVE: {
    color: "#D5D5D5",
    emoji: "🏖️",
    id: "LEAVE",
    string: "On Annual Leave",
    type: STATUS_TYPES.UNAVAILABLE,
  },
  SICK: {
    color: "#D5D5D5",
    emoji: "🤒",
    id: "SICK",
    string: "On Sick Leave",
    type: STATUS_TYPES.UNAVAILABLE,
  },
};

export const statusesByType = (statusType) =>
  Object.entries(STATUSES)
    .filter(([_, { type }]) => type === statusType)
    .map(([_, { id }]) => id);
