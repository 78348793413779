import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
});

export const logout = () => firebase.auth().signOut();

export const onAuthStateChangedEffect = (setAuthPending, setUser) => () => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (!user) {
      setAuthPending(true);
    } else {
      setAuthPending(false);
      setUser(user);
    }
  });
};

export const signInWithGooglePopup = () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(googleProvider);
}

export default firebase;
