import firebase from "./firebase";

import { STATUSES, NO_TEAM } from "./constants";

const NEW_BEES = "new-bees";
const TEAMS = "teams";
const CONFIG = "beehive-config";

export const createBeefromUser = (user) =>
  firebase.firestore().collection(NEW_BEES).doc(user.uid).set(
    {
      email: user.email,
      name: user.displayName,
      itTeamId: NO_TEAM,
      status: STATUSES.FINISHED.id,
      statusSince: firebase.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );

export const createBeeFromOldUser = (userId, user) => {
  firebase.firestore().collection("new-bees").doc(userId).set(
    { ...user, itTeamId: NO_TEAM },
    { merge: true }
  );
}

export const getTeams = async () => {
  return await firebase
    .firestore()
    .collection(TEAMS)
    .get()
}

export const verifyUser = (user) => {
  firebase.firestore().collection("bees").where("email", "==", user.email).get()
    .then((result) => {
      let userAlreadyExists;
      result.forEach(doc => {
        userAlreadyExists = doc.data();
      })

      if (userAlreadyExists) {
        return createBeeFromOldUser(user.uid, userAlreadyExists);
      } else {
        return createBeefromUser(user);
      }
    })
}

export const subscribeToBee = (uid, handler) =>
  firebase.firestore().collection(NEW_BEES).doc(uid).onSnapshot(handler);

export const subscribeToBees = async (handler) =>
  firebase
    .firestore()
    .collection(NEW_BEES)
    .onSnapshot(handler);

export const subscribeToConfig = (handler) =>
  firebase.firestore().collection(CONFIG).doc("web").onSnapshot(handler);

export const subscribeToPins = (pins, handler) =>
  firebase
    .firestore()
    .collection(NEW_BEES)
    .where("email", "in", pins)
    .onSnapshot(handler);

export const addPinForUid = (email, uid) =>
  firebase
    .firestore()
    .collection(NEW_BEES)
    .doc(uid)
    .update({
      pins: firebase.firestore.FieldValue.arrayUnion(email),
    });

export const removePinForUid = (email, uid) =>
  firebase
    .firestore()
    .collection(NEW_BEES)
    .doc(uid)
    .update({
      pins: firebase.firestore.FieldValue.arrayRemove(email),
    });

export const setStatusForUid = (status, uid) =>
  firebase.firestore().collection(NEW_BEES).doc(uid).update({
    status,
    statusSince: firebase.firestore.FieldValue.serverTimestamp(),
  });

export const setTeamForUid = (team, uid) =>
  firebase.firestore().collection(NEW_BEES).doc(uid).update({
    "clientConfig.web.selectedTeam": team,
  });

export const setSelectedLayoutForUid = (layoutId, uid) =>
  firebase.firestore().collection(NEW_BEES).doc(uid).update({
    "clientConfig.web.selectedLayout": layoutId,
  });

export const setShowPinsForUid = (showPins, uid) =>
  firebase.firestore().collection(NEW_BEES).doc(uid).update({
    "clientConfig.web.showPins": !!showPins,
  });
