import React from "react";

import { Avatar, Card, CardContent, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default () => (
  <Card>
    <CardContent>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Skeleton animation="wave" variant="circle">
            <Avatar />
          </Skeleton>
        </Grid>
        <Grid item xs={4}>
          <Skeleton animation="wave" variant="text" />
          <Skeleton animation="wave" variant="rect" height={34} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
