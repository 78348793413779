import React from "react";

import { Grid } from "@material-ui/core";

import TeamListingCard from "./TeamListingCard";

export default ({ teamMembers }) => {
  if (!teamMembers) return null;

  return (
    <Grid item xs={12}>
      <TeamListingCard name={"AtoZ"} teamMembers={teamMembers} />
    </Grid>
  );
};
