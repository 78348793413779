import React from "react";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default ({ prompt }) => (
  <Card>
    <CardContent>
      <Typography variant="subtitle1">{prompt}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="text" />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
