import React from "react";

import { Grid } from "@material-ui/core";

import TeamListingCard from "./TeamListingCard";

import { STATUS_TYPES, statusesByType } from "../constants";

const groupings = [
  { name: "Available", ids: statusesByType(STATUS_TYPES.AVAILABLE) },
  { name: "Busy", ids: statusesByType(STATUS_TYPES.BUSY) },
  { name: "Not at Work", ids: statusesByType(STATUS_TYPES.UNAVAILABLE) },
];

export default ({ teamMembers }) => {
  if (!teamMembers) return null;

  return groupings.map(({ name, ids }) => (
    <Grid key={name} item xs={12}>
      <TeamListingCard
        name={name}
        teamMembers={teamMembers.filter(
          ({ status }) => ids.indexOf(status) !== -1
        )}
      />
    </Grid>
  ));
};
